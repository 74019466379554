import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactPlayer from "react-player";
import "../style/normalize.css";
import "../style/all.scss";
import styles from "../style/pages/video.module.scss";

const Page = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const youtubeUrl = "https://youtu.be/lhLRZaCNYs8";

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Videos"
        keywords={[`video`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template">
        <div className={styles.grid}>
          <div className={styles.container}>
            <ReactPlayer
              url={youtubeUrl}
              controls
              width="100%"
              height="420px"
            />
          </div>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => <Page location={props.location} data={data} {...props} />}
  />
);
